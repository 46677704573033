.custom-navbar {
  padding-bottom: 10px;
  padding-top: 0;
  width: 180px;
  height: 100%;
  left: 0;
  background-color: #FF7940;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #FF7940;
  }
  &::-webkit-scrollbar-thumb {
    background: #FFF3E5;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #FFF3E5;
  }
  .logo-sec {
    width: 100%;
    .logo-wrp {
      span {
        display: block;
        img {
          width: 100%;
          background-image: url('../../../assets/images/logoBg.png');
          height: 165px;
          object-fit: contain;
          object-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          padding: 0 50px 45px 0;
        }
      }

      
    }
  }

  .custom-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .menu-wrp {
    display: flex;
    height: 100%;
    padding-top: 25px;
    flex-direction: column;
    width: 100%;
    button{
      flex-direction: column;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 0;
      margin-bottom: 1px;
    }
    a:hover{
      background: #FFF3E5;
      span{
        color: #FF7940;
      }
      svg{
        path{
          fill: #FF7940;
        }
      }
    }
  }
}