.auth-layout{
    max-width: 1980px;
    margin: 0 auto;
    .flex-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .form-sec-wrp{
            width: 50%;
            max-width: 450px;
            padding: 25px;
            margin-top: 20px;
            margin-bottom: 20px;
            box-shadow: 0px 0px 5px 2px #f1f1f1;
            border-radius: 20px;
            padding-top: 30px;
            padding-bottom: 55px;
            .auth-logo{
                display: table;
                width: 85px;
                margin: 0 auto;
            }
            .auth-form{
                max-width: 375px;
                margin: 0 auto;
                .form-tile{
                    padding-top: 20px;
                    padding-bottom: 25px;
                    text-align: center;
                    h3{
                        font-size: 24px;
                    }
                    h4{
                        font-size: 16px;
                    }
                }
                .login-form{
                    .standard-btn{
                        margin-top: 20px;
                    }
                }
                .additional-btn{
                    p{
                        margin: 0;
                        margin-top: 10px;
                        font-size: 14px;
                        a{
                            color: #FFAE00;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .form-img-wrp{
            width: 50%;
            min-height: 100vh;
            max-height: 1090px;
            span{
                padding: 15px 0px 15px 0px;
                display: block;
                height: 100%;
                position: relative;
                img{
                    height: calc(100% - 30px);
                    object-fit: cover;
                    object-position: center;
                    position: absolute;
                    left: 0;
                    top: 15px;
                    border-radius: 25px;
                }
            }
        }
    }    
}

.Signup-wrp{

}