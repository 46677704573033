.app-text-input-container {
    .form-group{
        padding-bottom: 45px;
        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
            padding-right: 0;
            .MuiInputAdornment-root{
                position: absolute;
                right: 15px;
                padding: 0;
                button.MuiButtonBase-root{
                    &:hover{
                        background: transparent;
                    }
                    svg{
                        width: 1.2em;
                    }
                }
            }
            input{
                padding-right: 60px;
            }
        }
        .MuiFormHelperText-root{
            color: #ffffff;
            font-style: normal;
            font-weight: 300;
            font-family: 'Poppins', sans-serif;
        }
    }
    .MuiFormControl-root{
        width: 100%;
        position: relative;
        .MuiInputLabel-shrink{
            top: -8px;
            transform: translate(12px, -18px) scale(1);
            color: #ffffff !important;
        }
        .MuiInputBase-formControl {
            input {
                height: 45px;
                border-radius: 5px;
                width: 100%;
                padding: 5px 35px;
                font-size: 14px;
                line-height: 45px;
                color: #6C6C6C;
                font-family: 'Poppins', sans-serif;
                background: #ffffff;
            }
            .MuiOutlinedInput-notchedOutline{
                border: none;
            }
        }
        .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
            color: #ffffff;
        }
    }
}
.app-text-input-container.no-padding-btm{
    .form-group{
        padding-bottom: 0 !important;
        .MuiInputBase-root{
            padding-right: 0 !important;
            .MuiInputAdornment-root{
                position: absolute !important;
                right: 15px !important;
                padding: 0 !important;
            }
        }
    }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}