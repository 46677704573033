html,
body {
    background-color: #fff;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto !important;
    font-family: 'Poppins', sans-serif;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.f-wrp {
    width: 100%;
    display: block;
    position: relative;
    float: left;
}

img {
    width: 100%;
    display: block;
}

ul,
li {
    list-style: none;
    padding-left: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}


.main-content-wrapper.f-wrp {
    width: calc(100% - 180px);
    float: right;
    padding: 25px;
}

.panel-main-header {
    margin-bottom: 20px;
}

.panel-main-header h1 {
    color: #FF7940;
    margin: 0;
    font-size: 36px;
    font-family: Andika New Basic;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.panel-main-header p {
    color: #5D5848;
    margin-top: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
}

.panel-form-sec.f-wrp {
    display: flex;
    background: #FFF3E5;
    border-radius: 24px;

}

.form-category-sec.f-wrp {
    max-width: 250px;
    background: #B4AC9C;
    padding: 20px;
    border-radius: 24px;

}

.form-select-list:not(:last-child) {
    margin-bottom: 15px;
}

.form-detail-sec.f-wrp {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 70vh;
}

.button-sec.f-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;
}

.pln-btn,
.fill-btn,
.cancel-btn {
    display: inline-block;
    height: 45px;
    padding: 0px 30px;
    border-radius: 50px;
    border: 2px solid #FF7940;
    min-width: 140px;
    font-weight: 700;
}

.cancel-btn {
    border-color: transparent;
}

.pln-btn {
    background: transparent;
}

.fill-btn {
    background-color: #FF7940;
    color: #FFF4EE;
}

.fill-btn:hover {
    background-color: #FF7940;
    color: #FFF4EE;
    opacity: 0.9;
}

.button-sec.f-wrp button:not(:last-child) {
    margin-right: 15px;
}

.selectList {
    padding: 0;
    border-radius: 10px;
}

.selectList span {
    color: #5D5848;
}

.selectList .MuiListItemText-primary {
    font-weight: 700;
}

.selectList .MuiCollapse-root.MuiCollapse-vertical .MuiListItemText-primary {
    font-weight: normal;
}

.selectList .MuiCollapse-root.MuiCollapse-vertical ul li>.MuiButtonBase-root {
    padding: 10px;
    padding-left: 16px;
}

.selectList .MuiCollapse-root.MuiCollapse-vertical ul li>.MuiButtonBase-root .MuiListItemIcon-root {
    min-width: auto;
    display: none;
}
.panel-form input{
    background-color: #FFF4EE;
}


/* nav */
.active-nav {
    background-color: burlywood;
}






















































































































@media only screen and (max-width: 800px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 650px) {}

@media only screen and (max-width: 500px) {}

@media only screen and (max-width: 450px) {}

@media only screen and (min-width: 600px) {
    .custom-header {
        min-height: 45px;
    }
}